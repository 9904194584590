import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import HeaderCampaign from "../components/headerCampaign"
import AcordeonKD from "../components/acordeonKD"
import "../styles/landing-campaign.scss"
import "../styles/kit-digital.scss"
import "../styles/contacto.scss"
import { IconContext } from "react-icons"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function KitDigital(props) {
  const [nombre, setNombre] = useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [mensajeError, setMensajeError] = useState("")
  const [respuesta, setRespuesta] = useState("")

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("leadbedigital")
    //--> grab the generated token by the reCAPTCHA
    // Prepare the data for the server, specifically body-parser

    const data = JSON.stringify({ nombre, email, telefono, result })
    // POST request to your server

    fetch(`${process.env.API_URL}/leadskitdigitals`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then(res => res.json())
      .then(data => {
        const success = data.success
        const score = data.score

        if (success && score >= 0.7) {
          setMensajeError("Gracias por ponerte en contacto con nosotros")
          setRespuesta("correcto")
          setNombre("")
          setEmail("")
          setTelefono("")
          setIsChecked(false)
        } else {
          setMensajeError(
            "Ha habido un error en el envío del formulario, por favor inténtalo de nuevo"
          )
          setRespuesta("error")
        }
      })
  }

  return (
    <Layout>
      <Seo
        title="Programa de subvenciones para la digitalización Kit Digital"
        pathname={props.location.pathname}
        imagen="https://bullhost.security/img/cabeceras/digitalizacion-kit-digital-bullhost.webp"
        robots="index,follow"
      />
      <div className="cabecera-fixed">
        <HeaderCampaign
          titulo="Programa de subvenciones para la digitalización Kit Digital"
          imagen="/img/cabeceras/digitalizacion-kit-digital-bullhost.webp"
        />
        <div className="intro-campaign">
          <p>
            Nuestro equipo te ayuda en todo el proceso de solicitud y gestión de
            esta subvención. Tú te centras en tu negocio, y nosotros nos
            ocupamos de toda la gestión de la ayuda.
          </p>
        </div>
      </div>
      <div className="cuerpo-servicio">
        <section className="limitador">
          <StaticImage
            className="logos-entidades-kit-digital"
            src="../../static/img/landings/kitdigital/logos-entidades-kit-digital.webp"
            objectFit="contain"
            alt="Bullhost y Kit Digital"
          />
          {/* <p className="parrafo-destacado destacado-landing">
            Bullhost es Agente Digitalizador para Kit Digital, y aquí te
            mostramos las soluciones profesionales que tenemos autorizadas para
            el programa de ayudas Kit Digital.
          </p> */}
        </section>
        <section className="bloque-oscuro">
          <div className="overlay-contacto"></div>
          <div className="header-inicio limitador-inicio">
            <div className="detalle destacado">
              <p>¿QUÉ ES KIT DIGITAL?</p>
            </div>
            <div className="titulo">
              <p>
                Kit Digital es una necesaria iniciativa del Gobierno de España,
                subvencionada por los fondos europeos <i>Next Generation EU</i>{" "}
                que están dentro del Plan de Recuperación, Transformación y
                Resiliencia. Su objetivo es subvencionar la implantación de
                soluciones digitales para que tu organización pueda experimentar
                un avance significativo en el nivel de madurez digital. Desde{" "}
                <a
                  href="https://www.acelerapyme.es/sites/acelerapyme/files/2021-12/BOE-A-2021-21873.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aquí
                </a>{" "}
                puedes acceder a las bases que regulan la concesión de estas
                ayudas para la digitalización.
              </p>
            </div>
          </div>
        </section>
        <section className="limitador contenido__servicio kdtablet">
          <div className="grid-landing">
            <div className="gridtexto">
              <h2>¿Para qué son estas ayudas?</h2>
              <p>
                Te contamos qué tipo de proyectos puedes acometer con este
                programa de subvenciones dotado de 3067 millones de euros, y
                enmarcado en el Plan de Recuperación, Transformación y
                Resiliencia de la agenda España Digital 2025.
              </p>
            </div>
            <div className="centrado-campaign betablet">
              <StaticImage
                src="../../static/img/landings/kitdigital/kit-digital-tablet.webp"
                // objectFit="contain"
                alt="Bullhost y Kit Digital"
              />
            </div>
          </div>
          <div className="tabla-maximos">
            <h3>Importes máximos de las ayudas</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>Segmentos de beneficiarios</th>
                  <th>Importe bono digital</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Segmento I. Pequeñas empresas de entre 10 y menos de 50
                    empleados
                  </td>
                  <td className="col-precio">12.000 €</td>
                </tr>
                <tr>
                  <td>
                    Segmento II. Pequeñas empresas o Microempresas de entre 3 y
                    menos de 10 empleados
                  </td>
                  <td className="col-precio">6.000 €</td>
                </tr>
                <tr>
                  <td>
                    Segmento III. Pequeñas empresas o Microempresas de entre 1 y
                    menos de 3 empleados y personas en situación de autoempleo
                  </td>
                  <td className="col-precio">2.000 €</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        {/* SECCION ACORDEONES */}
        <section
          id="acordeonesKD"
          className="limitador contenido__servicio kit-acordeon"
        >
          <AcordeonKD panels={panels} />
        </section>
        {/* SECCION FORMULARIO */}
        <section className="limitador contenido__servicio">
          <h2 className="titulo-form">¿Quieres más información?</h2>
          <div className="bloque-noasistir">
            <div className="prueba-demo">
              <div className="bloque-noasistir-p">
                <p className="bloque-noasistir-p">
                  Recuerda que desde Bullhost te ayudamos en todo el proceso:
                  con tu solicitud de la ayuda a fondo perdido, con la gestión
                  de la subvención y ejecutando tu proyecto con todo el know how
                  de nuestro equipo de IT.
                </p>
                <p className="bloque-noasistir-p">
                  Para hacerlo más fácil contactaremos contigo en cuanto
                  recibamos tu solicitud.
                  {/* y seremos tu Agente Digitalizador
                  seleccionándonos como tal en Acelera PYME. */}
                </p>
              </div>
              <div className="cuerpo-contacto bloque-noasistir-div">
                <div className="caja-formulario">
                  <form
                    className="form-contacto"
                    method="post"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <label htmlFor="nombre">Nombre</label>
                    <input
                      name="nombre"
                      placeholder="Nombre*"
                      required
                      value={nombre}
                      onChange={e => setNombre(e.target.value)}
                    />

                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email*"
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />

                    <label htmlFor="telefono">Teléfono</label>
                    <input
                      type="tel"
                      name="telefono"
                      placeholder="Teléfono*"
                      maxLength="14"
                      minLength="9"
                      required
                      value={telefono}
                      onChange={e => setTelefono(e.target.value)}
                    />

                    <div className="caja-checkbox">
                      <input
                        type="checkbox"
                        id="privacidad"
                        name="privacidad"
                        // defaultChecked={isChecked}
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                      <label htmlFor="privacidad" className="label-check">
                        He leído y acepto la{" "}
                        <Link to="/politica-de-privacidad/">
                          política de privacidad
                        </Link>
                      </label>
                    </div>

                    <div
                      className={`mensaje-formulario
                                ${respuesta}                              
                            `}
                      dangerouslySetInnerHTML={{ __html: mensajeError }}
                    />

                    <IconContext.Provider value={{ color: "#1f1f1f" }}>
                      <input
                        type="submit"
                        value="Enviar"
                        className="boton-enviar"
                        disabled={!isChecked}
                      />
                    </IconContext.Provider>
                    <p className="texto-recaptcha">
                      Este sitio está protegido por reCAPTCHA y se aplican la{" "}
                      <a href="https://policies.google.com/privacy">
                        política de privacidad
                      </a>{" "}
                      y los{" "}
                      <a href="https://policies.google.com/terms">
                        términos del servicio
                      </a>{" "}
                      de Google.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <div id="grid-entradas">
            <div className="row">
              <h2>Evento de presentación</h2>
              <p>
                El día 4 de febrero a las 9:30 se realizó un evento de
                presentación del programa en el Palacio de Congresos Europa
                (Auditorio María de Maeztu), en Vitoria-Gasteiz.
              </p>
              <p>A continuación puedes ver el vídeo del evento:</p>
            </div>
            <div className="container-video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/tSiRAfgqZP8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div> */}
        </section>
      </div>
    </Layout>
  )
}

const panels = [
  {
    label: "Sitio web y Presencia en internet",
    precio: "desde 1.995€",
    subtitulo:
      "El principal objetivo de esta categoría es que tu negocio pueda tener una página web y darte visibilidad en internet.",
    // content: "aweawe",
    content: `
    <p class="destacado">Funcionalidades y servicios</p>
    <ul>
      <li><span>Dominio:</span> la solución incluye el dominio de tu web durante un plazo mínimo de doce meses. La titularidad del dominio será tuya por completo.</li>
      <li><span>Hosting:</span> también tendrás a tu disposición el alojamiento de la página web durante un plazo mínimo de doce meses. </li>
      <li><span>Diseño de la página web:</span> como parte del servicio tendrás la estructura de la web, con un mínimo de 3 apartados. Se consideran páginas o apartados de una web, elementos tales como: página de inicio (Landing Page), presentación de la empresa, formulario de contacto, descripción de productos, datos de contacto, mapa del sitio (sitemap), etc.</li>
      <li><span>Web responsive:</span> tu web se adaptará perfectamente a cualquier tipo de dispositivo.</li>
      <li><span>Accesibilidad:</span> el diseño de tu web cumplirá con los criterios de conformidad de nivel AA de las Pautas WCAG-2.1.</li>
      <li><span>Posicionamiento básico en internet:</span> la información de tu pyme será indexable por los principales motores de búsqueda (On-Page).</li>
      <li><span>Autogestionable:</span> dispondrás también de una plataforma de gestión de contenidos para que puedas modificar los contenidos de tu web sin necesidad de ayuda.</li>
      <li><span>Optimización de la presencia en buscadores (SEO básico):</span> Análisis de palabras clave, SEO On-Page de 2 páginas o apartados, indexación y jerarquización del contenido.</li>
    </ul>
    <p class="destacado">Importe máximo de la ayuda</p>
    <ul>
        <li>0 < 3 empleados: 2.000€</li>
        <li>3 < 9 empleados: 2.000€</li>
        <li>10 < 50 empleados: 2.000€</li>
    </ul>
    `,
  },
  {
    label: "Comercio Electrónico",
    precio: "desde 5.500€",
    subtitulo:
      "El principal objetivo es crear tu tienda online para la venta de tus productos o servicios. Además de dar visibilidad a tu marca, dispondrás de un e-commerce o tienda online.",
    content: `<p class="destacado">Funcionalidades y servicios</p>
      <ul>
        <li><span>Creación de la tienda online o E-Commerce y alta del catálogo de productos:</span> tendrás a tu disposición un catálogo en tu tienda online, en el que estarán disponibles tus productos, mediante su alta, importación o carga. La solución incluye la carga de al menos 100 referencias de tus productos, salvo que no dispongas de este número, en cuyo caso podrá ser menor.</li>
        <li><span>Métodos de pago:</span> el Agente Digitalizador que hayas seleccionado configurará e integrará los métodos de pago en tu tienda online.</li>
        <li><span>Diseño Responsive:</span> tu E-Commerce se podrá utilizar en todo tipo de dispositivos.</li>
        <li><span>Accesibilidad:</span> el diseño de tu tienda online cumplirá con los criterios de conformidad de nivel AA de las Pautas WCAG-2.1.</li>
        <li><span>Posicionamiento básico en internet:</span>  tu tienda online será indexable por los principales motores de búsqueda (On-Page).</li>
        <li><span>Autogestionable:</span> dispondrás también de una plataforma de gestión de contenidos para que puedas modificar los contenidos de tu web sin necesidad de ayuda.</li>
        <li><span>Formas de envío:</span> tendrás configurados e integrados los métodos de envío digital y físico de los productos de tu tienda online.</li>
      </ul>
      <p class="destacado">Importe máximo de la ayuda</p>
      <ul>
          <li>0 < 3 empleados: 2.000€</li>
          <li>3 < 9 empleados: 2.000€</li>
          <li>10 < 50 empleados: 2.000€</li>
      </ul>`,
  },
  {
    label: "Gestión de redes sociales",
    precio: "desde 2.995€/año",
    subtitulo:
      "En esta categoría el objetivo es promocionar tu empresa en redes sociales.",
    content: `<p class="destacado">Funcionalidades y servicios</p>
      <ul>
        <li><span>Social Media Plan:</span> contarás con una estrategia de redes sociales alineada con tu misión y visión, para que sea relevante y conecte con tus potenciales clientes y fidelice a aquellos usuarios que ya lo sean.</li>
        <li><span>Monitorización de redes sociales:</span> tendrás monitorizados y controlados de manera periódica los impactos de las acciones en redes sociales para ver tus resultados y saber si estás cumpliendo con los objetivos de tu estrategia.</li>
        <li><span>Optimización de la red/Auditoría Social Media:</span> te ayudará a optimizar el rendimiento, analizando diferentes canales sociales.</li>
        <li><span>Gestión de una red social:</span> el Agente Digitalizador que hayas seleccionado para proporcionarte la solución te administrará el perfil/usuario en, al menos, una red social.</li>
        <li><span>Publicación de posts semanales:</span> el Agente Digitalizador publicará un mínimo de 4-8 entradas (posts) mensuales en tus redes sociales.</li>
      </ul>
      <p class="destacado">Importe máximo de la ayuda</p>
      <ul>
          <li>0 < 3 empleados: 2.000€</li>
          <li>3 < 9 empleados: 2.500€</li>
          <li>10 < 50 empleados: 2.500€</li>
      </ul>`,
  },
  {
    label: "BI y analítica",
    precio: "desde 4.995€",
    subtitulo:
      "Esta categoría quiere posibilitarte la explotación de datos de tu empresa para la mejora del proceso de toma de decisiones.",
    content: `<p class="destacado">Funcionalidades y servicios</p>
      <ul>
        <li><span>Integración de datos con otras bases de datos:</span> tendrás acceso a otras bases de datos y a la realización de comparaciones con los datos expuestos.</li>
        <li><span>Almacenamiento de datos:</span> dispondrás de una capacidad de almacenamiento como mínimo de 1 GB por usuario.</li>
        <li><span>Creación de paneles de datos estructurados y visuales:</span> te ayudará a optimizar el rendimiento, analizando diferentes canales sociales.</li>
        <li><span>Exportación de datos:</span> podrás exportar datos a imágenes o a documentos de Excel, creando sinergias y compatibilidades con distintos programas que utilices comúnmente.</li>
      </ul>
      <p class="destacado">Importe máximo de la ayuda</p>
      <ul>
          <li>0 < 3 empleados: 1.500€ (incluye 1 usuario)</li>
          <li>3 < 9 empleados: 2.000€ (incluye 1 usuario)</li>
          <li>10 < 50 empleados: 4.000€ (incluye 3 usuarios)</li>
      </ul>`,
  },
  {
    label: "Gestión de procesos",
    precio: "desde 2.000€",
    subtitulo:
      "El principal objetivo de esta categoría es digitalizar y/o automatizar procesos de tu negocio, relacionados con los aspectos operativos o productivos.",
    content: `<p class="destacado">Funcionalidades y servicios</p>
      <ul>
        <li><span>Digitalización y/o automatización de procesos y flujos de trabajo.</span> tendrás digitalizados y/o automatizados procesos como:
          <ul>
              <li><span>Contabilidad/finanzas:</span> cuentas por cobrar/por pagar, gestión de activos y generación de cierres y balances, etc.</li>
              <li><span>Facturación:</span> automatización de los procesos de facturación con la generación de presupuestos, albaranes y facturas.</li>
              <li><span>Proyectos:</span> control de presupuestos, costes, estimaciones, optimización de los recursos, etc.</li>
              <li><span>Inventario:</span> previsión, niveles de stock, envíos, distribuciones, devoluciones y cancelaciones, etc.</li>
              <li><span>Compras y pagos:</span> gestión de pedidos de compra y proveedores.</li>
              <li><span>Recursos humanos:</span> gestión de los recursos humanos, nóminas, etc.</li>
              <li><span>Logística:</span> gestión de flotas y rutas, entre otras.</li>
          </ul>
          </li>
        <li><span>Integración con diversas plataformas:</span> la solución dispondrá de APIs o Web Services para su integración con otras herramientas.</li>
        <li><span>Actualizable:</span> accederás a actualizaciones de la solución con nuevas versiones.</li>
        <li><span>Escalable:</span> si tu empresa crece o cambia estructuralmente, la solución se adaptará a estos cambios.</li>
        <li><span>Cumplimiento:</span> podrás asegurar el cumplimiento de la normativa aplicable, en concreto del Reglamento por el que se regulan las obligaciones de facturación del Real Decreto 1619/2012, así como cualquier normativa de aplicación.</li>
      </ul>
      <p class="destacado">Porcentajes de ejecución asociados a las fases</p>
      <ul>
        <li>Primera: 70 %</li>
        <li>Segunda: 30 %</li>
      </ul>
      <p class="destacado">Importe máximo de la ayuda</p>
      <ul>
          <li>0 < 3 empleados: 2.000€ (incluye 1 usuario)</li>
          <li>3 < 9 empleados: 3.000€ (incluye 3 usuario)</li>
          <li>10 < 50 empleados: 6.000€ (incluye 10 usuarios)</li>
      </ul>`,
  },
  {
    label: "Servicios de oficina virtual",
    precio: "desde 10.000€",
    subtitulo:
      "El principal objetivo de esta categoría es implantar en tu empresa soluciones interactivas y funcionales que permitan la colaboración más eficiente entre tus trabajadores.",
    content: `<p class="destacado">Funcionalidades y servicios</p>
    <ul>
        <li><span>Colaboración en equipos de trabajo:</span> tendrás asistencia por parte del proveedor en el proceso creativo de ideación o gestión de equipos para la gestión colaborativa de proyectos, compartir recursos y/o conocimientos, dotar de herramientas para la interacción de los equipos en los procesos de ideación o resolución de problemas, así como la configuración y personalización de flujos de trabajo, tareas, etc.</li>
        <li><span>Almacenar y compartir archivos:</span> dispondrás de 1 TB de almacenamiento.</li>
        <li><span>Compatibilidad con dispositivos móviles:</span> Tu solución será compatible con dispositivos móviles.</li>
        <li><span>Calendario y agenda:</span> te permitirá organizar un calendario y tus tareas previstas.</li>
    </ul>
    <p class="destacado">Importe máximo de la ayuda</p>
    <ul>
        <li>0 < 3 empleados: 250€/usuario (hasta 2 usuarios)</li>
        <li>3 < 9 empleados: 250€/usuario (hasta 9 usuarios)</li>
        <li>10 < 50 empleados: 250€/usuario (hasta 48 usuarios)</li>
    </ul>`,
  },
  {
    label: "Comunicaciones seguras",
    precio: "entre 1.500€ y 6.000€",
    subtitulo:
      "El objetivo de esta categoría es proveer de seguridad a las conexiones entre los dispositivos de tus empleados y la empresa.",
    content: `<p class="destacado">Funcionalidades y servicios</p>
    <ul>
        <li><span>SSL:</span> dispondrás de un protocolo de capa de sockets seguros, para crear una conexión segura y cifrada.</li>
        <li><span>Cifrado de extremo a extremo:</span> tendrás las comunicaciones cifradas en todo su recorrido, con el objetivo de prevenir ataques.</li>
        <li><span>Logs de conexión:</span> serás capaz de mantener un registro de los dispositivos que se han conectado a tu red privada.</li>
        <li><span>Control de acceso:</span> tendrás la posibilidad de permitir la conexión a tu red privada única y exclusivamente a los dispositivos autorizados por ti.</li>
        <li><span>Dispositivos móviles:</span> podrás hacer uso de esta solución desde dispositivos móviles.</li>
        <li><span>Configuración inicial y actualizaciones de seguridad:</span> dispondrás de una configuración inicial para que puedas usarlo correctamente, con las respectivas actualizaciones de firmas de malware y otros datos para detección de amenazas además de las actualizaciones de software de seguridad periódicas requeridas.</li>
    </ul>
    <p class="destacado">Importe máximo de la ayuda</p>
    <ul>
        <li>0 < 3 empleados: 125/usuario (hasta 2 usuarios)</li>
        <li>3 < 9 empleados: 125/usuario (hasta 9 usuarios)</li>
        <li>10 < 50 empleados: 125/usuario (hasta 48 usuarios)</li>
    </ul>
    `,
  },
  {
    label: "Ciberseguridad",
    precio: "desde 3.995€",
    subtitulo:
      "El objetivo de esta categoría es proporcionar seguridad básica y avanzada para los dispositivos de tus empleados.",
    content: `<p class="destacado">Funcionalidades y servicios</p>
    <ul>
        <li><span>Antimalware:</span> tendrás a tu disposición una herramienta que analice tu dispositivo, su memoria interna y los dispositivos de almacenamiento externos.</li>
        <li><span>Antispyware:</span> dispondrás de una herramienta que detecte y evite el malware espía.</li>
        <li><span>Correo seguro.</span> Tendrás herramientas de análisis del correo electrónico con las siguientes características:
            <ul>
                <li><span>Antispam:</span> con detección y filtro de correo no deseado.</li>
                <li><span>Antiphishing:</span> con detección de correos con enlaces o malware que se sospecha sirvan para robar credenciales.</li>
            </ul>
        </li>
        <li><span>Navegación segura.</span> Tendrás asegurado:
            <ul>
                <li><span>Control de contenidos.</span></li>
                <li><span>Antiadware</span> para evitar anuncios maliciosos.</li>
            </ul>
        </li>
        <li><span>Análisis y detección de amenazas:</span> serás capaz de conocer el comportamiento de las amenazas conocidas y nuevas.</li>
        <li><span>Monitorización de la red:</span> tendrás herramientas que analizan el tráfico de red y te alerten de amenazas.</li>
        <li><span>Configuración inicial y actualizaciones de seguridad:</span> dispondrás de una configuración inicial para su correcto uso, con las respectivas actualizaciones de firmas de malware y otros datos para detección de amenazas además de las actualizaciones de software de seguridad periódicas requeridas.</li>
        <li><span>Requisitos especiales de formación:</span> dispondrás de formación para la configuración del software de seguridad, y tendrás un kit de concienciación en ciberseguridad para complementar la solución con habilidades de firewall humano.</li>
    </ul>
    <p class="destacado">Importe máximo de la ayuda</p>
    <ul>
        <li>0 < 3 empleados: 125/dispositivo (hasta 2 dispositivos)</li>
        <li>3 < 9 empleados: 125/dispositivo (hasta 9 dispositivos)</li>
        <li>10 < 50 empleados: 125/dispositivo (hasta 48 dispositivos)</li>
    </ul>`,
  },
]
